body {
  margin: 0;
  font-size: 1.6rem;
}

@font-face {
  font-family: 'Perfect DOS VGA 437 Win';
  src: url('~../src/assets/fonts/Perfect DOS VGA 437 Win.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Georgia';
  src: url('~../src/assets/fonts/Georgia.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PokemonGB';
  src: url('~../src/assets/fonts/PokemonGB.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oracle Regular';
  src: url('~../src/assets/fonts/Oracle Regular.ttf');
  font-weight: normal;
  font-display: swap;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html,
body,
div#root {
  height: 100%;
}


*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
}

p,
pre,
span,
button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

:root {
  --c-main: #1aed1f;
  --f-main: 'Perfect DOS VGA 437 Win';
  --f-numbers: 'Perfect DOS VGA 437 Win';

  --c-white: #fff;
  --c-black: #000;
  --c-80s: #1aed1f;
  --c-80s-dark: #070707;
  --c-90s-game-area-bg: #FDFAC5;
  --c-faded-80s: #1a351a;
  --c-dark-1: #020202;
  --c-gray-1: #1a2f3f;
  --c-gray-2: #325c72;
  --c-gray-3: #81b2c3;
  --c-gray-4: #0E2E5F;
  --c-gray-5: #0a0a0a99;
  --c-gray-6: #f3f3f3;
  --c-gray-7: #0a0a0a99;
  --c-light-1-light: #DCB27D;
  --c-light-1: #d39f5d;
  --c-light-1-dark: #A97F4A;
  --c-light-2: #f0cc71;
  --c-light-3: #d2eaf1ad;
  --c-light-4: #3e686c;
  --c-light-5: #4f7d7b;
  --c-light-6: #132123;
  --c-light-7: #3C7C95;
  --c-light-8-light: #94809F;
  --c-light-8: #796087;
  --c-light-8-dark: #614D6C;
  --c-light-9-light: #B6838F;
  --c-light-9: #A46473;
  --c-light-9-dark: #83505C;
  --c-light-10-light: #6396AA;
  --c-light-10: #3C7C95;
  --c-light-10-dark: #306377;
  --c-light-11-light: #D26B5D;
  --c-light-11: #C74634;
  --c-light-11-dark: #9F382A;
  --c-light-12: #B5D5E0;
  --c-light-13: #76a2a0;
  --c-light-15-light: #85c9e4;
  --c-light-15: #6BA1B6;
  --c-light-15-dark: #558091;
  --c-light-16-light: #8cbd86;
  --c-light-16: #749A6F;
  --c-light-16-dark: #536d4f;
  --c-light-17: #385487;
  --c-light-18: #4F7D7B;

  --c-dark-green: #04690d80;
}